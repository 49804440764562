import React from 'react'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Kosugi Maru', sans-serif;
    /* font-family: 'Noto Sans JP', sans-serif; */
    font-size: 1rem;
    margin: 0;
    padding: 0;
    background: #439CBD;
    color: #ECEEED;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    text-align: center;
  }

  h1 {
    font-size: 4rem;
  }


  #naruto {
    font-size: 100px;
    margin: 2rem;
    transform: translate(-200px);
    /* animation: alternate linear infinite 2s leftM; */
    /* animation: cubic-bezier(0.42, 0, 0.58, 1) alternate infinite 4s leftM ; */
    animation: ease-in-out alternate infinite 2.5s leftM ;
  }

  #castle {
    font-size: 250px;
    margin: 1rem;
  }

  @keyframes leftM {
    from {
      transform: translate(200px);
    }
    to {
      transform: translate(-200px);
    }
  }
`

export default function App() {
  return(
    <div>
      <GlobalStyle />
      <h1>ミチコト</h1>
      <h2>よこそう、このサイトはまだ作ってるから後で戻ってね！</h2>
      <h2>面白いことくるよ。楽しみにしてる！😃</h2>
      <div id="naruto">🍥</div>
      <div id="castle">🏯</div>
    </div>
  )
}